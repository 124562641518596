import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Notre engagement' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      Nous prenons au sérieux notre engagement envers nos utilisateurs. Si vous avez besoin de notre aide pour votre projet, avez des questions sur l'utilisation du site ou rencontrez des difficultés techniques, n'hésitez pas à nous contacter.
      </Text>
    </Card>
  </Section>
)

export default Commitment
