import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  let iconStyles = {
    width: '10px',
    height: '10px'
  };

  let iconButtonStyles = {
    width: '12px',
    height: '12px',
    margin: ' 0 2px 0 0'
  };

  let tel = 'tel:' + phone;
  let mailto = 'mailto:' + email;

  return (
    <Section aside title='Collaboration'>
      <Card variant='paper'>
        {/* 
        <Text variant='p'>Interested in working together?</Text>
        <Text variant='p'>
          Suspendisse potenti. Mauris mollis diam tempus ut.
        </Text>
        */}
        {phone && (
          <Text>
            <IconButton variant='simple' role='presentation' style={iconButtonStyles}>
            <a href={tel}>
            <FaPhone style={iconStyles}/>
            </a>
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation' style={iconButtonStyles}>
            <a href={mailto}>
            <FaEnvelope style={iconStyles}/>
            </a>
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant='simple' role='presentation' style={iconButtonStyles}>
            <a href='https://www.google.com/maps/place/Lille' target="_blank">
            <FaLocationArrow style={iconStyles}/>
            </a>
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
